import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

type HttpErrorDto = {
  errors: HttpErrorItemDto[];
  instance: string;
  status: HttpStatusCode;
  type: string;
  traceId: string;
  title: string;
};

type HttpErrorItemDto = {
  name: string;
  reason: string;
};

export const getBadRequestErrorMessage = (
  httpErrorResponse: HttpErrorResponse
) => {
  const httpError: HttpErrorDto = httpErrorResponse.error;

  return httpError.errors?.map((error) => error.reason);
};
