import { APP_INITIALIZER, Injector, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import en from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from '@core/core.module';
import { AuthInterceptor } from '@core/interceptor/auth.interceptor';
import { AppStorage } from '@shared/storage/app-storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderInterceptor } from '@core/interceptor/header.interceptor';
import { ViewLoaderComponent } from '@shared/components/view-loader/view-loader.component';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ErrorPageComponent } from '@root/app/error-page/error-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthStoreService } from './core/services/auth-service/auth-store.service';
import { AuthService } from '@core/services/auth-service/auth.service';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { mapAuthTokenToInfoParser } from '@core/parsers/map-auth-token-to-info.parser';
import { PrintStoreService } from '@core/services/print-store.service';

registerLocaleData(en);

function httpLoaderFactory(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(
    new HttpClient(httpBackend),
    './assets/i18n/',
    `.json?v=${new Date().getTime()}`
  );
}

export function initializeApp(injector: Injector): () => Promise<void> {
  return async () => {
    // TODO: Move this initialization to be in init authorization store
    const authStoreService = injector.get(AuthStoreService);
    const authService = injector.get(AuthService);
    const printStoreService = injector.get(PrintStoreService);
    const accessToken = new URL(document.URL).searchParams.get('access_token');
    if (accessToken) {
      return firstValueFrom(
        authService.getTokenInfoByAccessToken(accessToken).pipe(
          tap((token) => {
            authStoreService.setTokenInfo(mapAuthTokenToInfoParser(token));
            printStoreService.setPrintMode('external');
          })
        )
      ) as Promise<unknown> as Promise<void>;
    }
    return Promise.resolve();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    /*
     * TranslateModule.forRoot must be called only on app module level
     * root module must  isolate to prevent child lazy loaded module from override app module translation
     * */
    TranslateModule.forRoot({
      defaultLanguage: AppStorage.getLanguage(),
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      isolate: false,
    }),
    ViewLoaderComponent,
    ErrorPageComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
