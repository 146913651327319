import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@root/environments/environment';
import { FileUploadResponseModel } from '@shared/attachments/models/file-upload-response.model';
import { FileUploadPayloadModel } from '@shared/attachments/models/file-upload-payload.model';
import { DocumentTypeEnum } from '@shared/attachments/enums/document-type.enum';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  private http = inject(HttpClient);
  private readonly attachmentFileApiPath = `${environment.apiUrl}/v2/Attachments`;

  private fileUploadKeys = (str: keyof FileUploadPayloadModel) => str;

  uploadFile(model: FileUploadPayloadModel) {
    const formData = new FormData();
    formData.append(this.fileUploadKeys('file'), model.file);
    formData.append(
      this.fileUploadKeys('requestType'),
      model.requestType.toString()
    );
    formData.append(
      this.fileUploadKeys('documentType'),
      model.documentType.toString()
    );
    if (
      model.documentType === DocumentTypeEnum.Other &&
      model.otherDocumentType
    ) {
      formData.append(
        this.fileUploadKeys('otherDocumentType'),
        model.otherDocumentType
      );
    }

    return this.http.post<FileUploadResponseModel>(
      `${this.attachmentFileApiPath}/Upload`,
      formData
    );
  }

  // Return the file blob directly
  getById(id: string) {
    return this.http.get(`${this.attachmentFileApiPath}/${id}`, {
      responseType: 'blob',
    });
  }

  // Return the file blob with the HttpResponse instead of Blob
  getByIdResponse(id: string) {
    return this.http.get(`${this.attachmentFileApiPath}/${id}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
