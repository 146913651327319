import { Injectable, signal } from '@angular/core';

type PrintMode = 'internal' | 'external';

@Injectable({
  providedIn: 'root',
})
export class PrintStoreService {
  //props
  private readonly $printModeValue = signal<PrintMode>('internal');
  readonly $printMode = this.$printModeValue.asReadonly();

  //methods
  setPrintMode(mode: PrintMode) {
    this.$printModeValue.set(mode);
  }
}
