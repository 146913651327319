import { inject, Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthCredentialsModel } from '@core/models/auth/auth-credentials.model';
import { AuthTokenDetailsDataModel } from '@core/models/auth/auth-token-details-data.model';
import { AuthRefreshTokenPayloadModel } from '@core/models/auth/auth-refresh-token-payload.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private httpClient = inject(HttpClient);
  private readonly baseUrl = `${environment.apiUrl}/v2/AccessControl`;

  login(payload: AuthCredentialsModel) {
    return this.httpClient.post<AuthTokenDetailsDataModel>(
      `${this.baseUrl}/Token`,
      payload
    );
  }

  refreshToken(model: AuthRefreshTokenPayloadModel) {
    return this.httpClient.post<AuthTokenDetailsDataModel>(
      `${this.baseUrl}/Token/RefreshToken`,
      model
    );
  }

  getTokenInfoByAccessToken(accessToken: string) {
    const headers = new HttpHeaders().append(
      'Authorization',
      `Bearer ${accessToken}`
    );
    return this.httpClient.post<AuthTokenDetailsDataModel>(
      `${this.baseUrl}/Token/ByAccessToken`,
      null,
      {
        headers,
      }
    );
  }
}
