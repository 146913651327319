@if (control && !control.valid && control.touched && !!control.errors) {
  <div class="inline-block my-1">
    <span class="flex flex-col text-danger text-xs">
      @if (control?.errors?.['required']) {
        <span>
          {{ 'validations.required' | translate }}
        </span>
      }
      @if (control?.errors?.['invalidIdNumber']) {
        <span>
          {{ 'validations.invalidIdNumber' | translate }}
        </span>
      }
      @if (control?.errors?.['invalidEmployeeId']; as errorMsg) {
        <span>
          {{ errorMsg }}
        </span>
      }
      @if (control?.errors?.['delegateRegisteredEmployee']) {
        <span>
          {{ 'validations.delegateRegisteredEmployee' | translate }}
        </span>
      }
      @if (control?.errors?.['customIdValidator']) {
        <span>
          {{ 'validations.customIdValidator' | translate }}
        </span>
      }
      @if (control?.errors?.['customPhoneValidator']) {
        <span>
          {{ 'validations.customPhoneValidator' | translate }}
        </span>
      }
      @if (control?.errors?.['email']) {
        <span>
          {{ 'validations.mustBeEmail' | translate }}
        </span>
      }
      @if (control?.errors?.['digit']) {
        <span>
          {{ 'validations.mustBeDigit' | translate }}
        </span>
      }
      @if (control?.errors?.['numbersOnly']) {
        <span>
          {{ 'validations.numbersOnly' | translate }}
        </span>
      }
      @if (control?.errors?.['invalidContractNumber']) {
        <span>
          {{
            'validations.invalidContractNumber'
              | translate
                : {
                    requiredLength:
                      control?.errors?.['invalidContractNumber'].requiredLength
                  }
          }}
        </span>
      }
      @if (control?.errors?.['invalidPersonalPhoneNumber']) {
        <span>
          {{
            'validations.invalidPersonalPhoneNumber'
              | translate
                : {
                    maxLength:
                      control?.errors?.['invalidPersonalPhoneNumber'].maxLength
                  }
          }}
        </span>
      }
      @if (control?.errors?.['exactValueValidator']) {
        <span>
          {{
            'validations.exactValueValidator'
              | translate
                : {
                    requiredValue:
                      control?.errors?.['exactValueValidator'].requiredValue
                  }
          }}
        </span>
      }
      @if (control?.errors?.['invalidPassportPhoneNumber']) {
        <span>
          {{
            'validations.invalidPassportPhoneNumber'
              | translate
                : {
                    maxLength:
                      control?.errors?.['invalidPassportPhoneNumber'].maxLength,
                    minLength:
                      control?.errors?.['invalidPassportPhoneNumber'].minLength
                  }
          }}
        </span>
      }
      @if (control?.errors?.['invalidPhone']) {
        <span>
          {{ 'validations.invalidPhone' | translate }}
        </span>
      }

      @if (control?.errors?.['minlength']) {
        <span>
          {{
            'validations.minLength'
              | translate
                : {
                    requiredLength:
                      control?.errors?.['minlength'].requiredLength
                  }
          }}
        </span>
      }

      @if (control?.errors?.['max']) {
        <span>
          {{ control?.errors?.['max'].max }}
          {{
            'validations.max'
              | translate
                : {
                    requiredLength: control?.errors?.['max'].max
                  }
          }}
        </span>
      }
      @if (control?.errors?.['min']) {
        <span>
          {{
            'validations.min'
              | translate
                : {
                    requiredLength: control?.errors?.['min'].min
                  }
          }}
        </span>
      }

      @if (control?.errors?.['maxlength']) {
        <span>
          {{
            'validations.maxLength'
              | translate
                : {
                    requiredLength:
                      control?.errors?.['maxlength'].requiredLength
                  }
          }}
        </span>
      }
      @if (control?.errors?.['minDateValidator']) {
        <span>
          {{
            'validations.minDateValidator'
              | translate
                : {
                    minDate:
                      control?.errors?.['minDateValidator']?.minDate
                      | georgianDate
                  }
          }}
        </span>
      }
      @if (control?.errors?.['minDateTimeValidator']) {
        <span>
          {{
            'validations.minDateValidator'
              | translate
                : {
                    minDate:
                      control?.errors?.['minDateTimeValidator']?.minDate
                      | georgianDateTimeUi
                  }
          }}
        </span>
      }
      @if (control?.errors?.['maxDateTimeValidator']) {
        <span>
          {{
            'validations.maxDateValidator'
              | translate
                : {
                    maxDate:
                      control?.errors?.['maxDateTimeValidator']?.maxDate
                      | georgianDateTimeUi
                  }
          }}
        </span>
      }
      @if (control?.errors?.['maxDateValidator']) {
        <span>
          {{
            'validations.maxDateValidator'
              | translate
                : {
                    maxDate:
                      control?.errors?.['maxDateValidator']?.maxDate
                      | georgianDate
                  }
          }}
        </span>
      }
      @if (control?.errors?.['minTimeValidator']) {
        <span>
          {{ 'validations.minTimeValidator' | translate }}
        </span>
      }
      @if (control?.errors?.['invalidGeneralNotes']) {
        <span>
          {{ 'validations.invalidGeneralNotes' | translate }}
        </span>
      }
      @if (control?.errors?.['invalidInspectionStudyOrReply']) {
        <span>
          {{ 'validations.invalidInspectionStudyOrReply' | translate }}
        </span>
      }
      @if (control?.errors?.['invalidStudyHandlingNote']) {
        <span>
          {{ 'validations.invalidStudyHandlingNote' | translate }}
        </span>
      }
      @if (control?.errors?.['invalidStudyNotes']) {
        <span>
          {{ 'validations.invalidStudyNotes' | translate }}
        </span>
      }
      @if (control?.errors?.['progressValueInvalid']) {
        <span>
          {{ 'validations.progressValueInvalid' | translate }}
        </span>
      }
      @if (control?.errors?.['atLeastOneProject']) {
        <span>
          {{ 'validations.atLeastOneProject' | translate }}
        </span>
      }
      <!--start of form errors for shift group details -->
      @if (control?.errors?.['selectOneObserverType']) {
        <span>
          {{ 'validations.selectOneObserverType' | translate }}
        </span>
      }
      @if (control?.errors?.['atLeastOneSecurityObservers']) {
        <span>
          {{ 'validations.atLeastOneSecurityObservers' | translate }}
        </span>
      }
      @if (control?.errors?.['atMostOneViceSecurityObserver']) {
        <span>
          {{ 'validations.atMostOneViceSecurityObserver' | translate }}
        </span>
      }
      <!--end of form errors for shift group details -->
      @if (control?.errors?.['employeeExistError']) {
        <span>
          {{ 'validations.employeeExistError' | translate }}
        </span>
      }
      @if (control?.errors?.['notUnique']) {
        <span>{{ 'validations.not-unique' | translate }}</span>
      }
      @if (control?.errors?.['englishLettersAndNumbersOnlyValidator']) {
        <span>
          {{ 'validations.english-letters-and-numbers-only' | translate }}
        </span>
      }
      @if (control?.errors?.['noFaceDetected']) {
        <span>{{ 'validations.noFaceDetected' | translate }}</span>
      }
      @if (control?.errors?.['invalidIssuingDate']) {
        <span>{{ 'validations.invalidIssuingDate' | translate }}</span>
      }
      @if (control?.errors?.['invalidNumberChars']) {
        <span>{{ 'validations.invalid-number-chars' | translate }}</span>
      }
      <ng-content select="[formCustomError]" />
    </span>
  </div>
}
