<textarea
  matInput
  cdkTextareaAutosize
  [required]="required"
  [disabled]="disabled"
  [maxlength]="maxLength()"
  [minlength]="minlength()"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
  (blur)="onBlur()"
  [(ngModel)]="$value"
  [placeholder]="placeholder | translate"
></textarea>
